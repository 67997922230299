<template>
  <div>
    <gl-title title="商品"></gl-title>
    <div>
      <gl-page-header>
        <el-form :model="mFormData" ref="listForm" :inline="true" size="mini">
          <el-form-item prop="firstCategory" label="分类">
            <selectCascader
              :one.sync="mFormData.firstCategory"
              :two.sync="mFormData.secondCategory"
              :list="options"
            ></selectCascader>
          </el-form-item>

          <el-form-item prop="itemName" label="输入产品名">
            <el-input
              v-model="mFormData.itemName"
              style="width: 900px"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="mReload">
              搜索
            </el-button>
            <el-button icon="el-icon-refresh" @click="mResetForm('listForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </gl-page-header>

      <gl-card v-loading="mLoading">
        <!--mTableData-->
        <div style="display: flex; flex-wrap: wrap">
          <div v-for="(item, index) in mTableData" :key="index">
            <div class="shopCard">
              <img class="shopCard-img" :src="item.pictureUrl" />
              <div class="shopCard-text">
                <div class="shopCard-text-title">
                  {{ item.itemName }}
                </div>
                <div class="shopCard-text-secondTitle">
                  <img
                    src="@/assets/tuijian.png"
                    style="height: 17px; width: 56px"
                    class="shopCard-text-secondTitle-label"
                  />
                  <div>建议零售价：¥{{ item.retailPrice }}/个</div>
                  <div class="shopCard-text-secondTitle-text">
                    {{ item.wholesaleRemarks }}
                  </div>
                </div>
                <div class="shopCard-text-secondTitle">
                  <img
                    src="@/assets/sale.png"
                    style="height: 15px; width: 15px"
                    class="shopCard-text-secondTitle-label"
                  />
                  <div class="shopCard-text-secondTitle-text">
                    {{ item.batchQuantity }}
                  </div>
                </div>

                <div class="shopCard-text-unction">
                  <div class="shopCard-text-unction-money">
                    <span class="shopCard-text-unction-money-symbol">¥</span>
                    <span class="shopCard-text-unction-money-text">{{
                      item.batchPrice
                    }}</span>
                    <span class="shopCard-text-unction-money-unit">/个</span>
                    <!-- <span class="shopCard-text-unction-money-advice"
                      >建议零售价：¥{{ item.retailPrice }}/个</span
                    > -->
                  </div>
                  <div style="display: flex">
                    <div
                      class="shopCard-text-unction-button2"
                      @click="goRouter(item.itemId)"
                    >
                      去下订
                    </div>

                    <div
                      class="shopCard-text-unction-button"
                      @click="goRouter(item.itemId, 'group')"
                    >
                      ¥{{ item.batchPrice }}拼团
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <gl-pagination
          :total="mTotal"
          :current-page="mPageNum"
          @currentChange="mHandleCurrentChange"
          @sizeChange="mHandleSizeChange"
        >
        </gl-pagination>
      </gl-card>
    </div>
  </div>
</template>

<script>
import { getCategoryTree, queryByType } from '@/api/coox/shop.js'
import ListMixin from '@/mixins/list.mixin.js'
import selectCascader from './selectCascader.vue'

export default {
  name: 'GoodsNewList',
  components: { selectCascader },
  props: {},
  mixins: [ListMixin],
  data() {
    // 这里存放数据
    return {
      options: [],
      formData: {},
    }
  },
  // 计算属性类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goRouter(target, type) {
      if (type === 'group') {
        this.$router.push({
          path: 'details',
          query: { itemId: target, type: 1 },
        })
      } else {
        this.$router.push({
          path: 'details',
          query: { itemId: target, type: 0 },
        })
      }
    },
    buyContent(target) {
      console.log('买东西')
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  async mounted() {
    const result = await getCategoryTree()
    this.options = result.data
    this.mGetListFun = queryByType
    this.mGetList()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="scss" scoped>
.shopCard {
  width: 800px;
  height: 205px;
  border-radius: 6px;
  background: #ffffff;
  display: flex;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  padding: 20px;
  margin: 0 20px 20px 0;
  &-img {
    width: 165px;
    height: 165px;
  }
  &-text {
    margin-left: 20px;
    height: 165px;
    width: 100%;
    &-title {
      font-size: 20px;
      font-family: PingFang SC;
      color: #3d3d3d;
      width: 500px;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-secondTitle {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #878787;
      margin-bottom: 8px;
      &-label {
        margin-right: 5px;
      }
    }
    &-unction {
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-money {
        display: inline-block;
        vertical-align: bottom;
        &-symbol {
          font-size: 20px;
          font-family: DIN Black;
          text-align: end;
          color: #3d3d3d;
          padding: 0;
        }
        &-text {
          font-family: DIN;
          font-size: 36px;
          font-weight: bold;
          color: #3d3d3d;
        }
        &-unit {
          font-family: PingFang SC;
          font-size: 20px;
          color: #3d3d3d;
        }
        &-advice {
          margin-left: 9px;
          font-size: 14px;
          color: #878787;
        }
      }
      &-button {
        cursor: pointer;
        width: 120px;
        height: 40px;
        border-radius: 10px;
        background: #409eff;
        box-shadow: 0px 4px 10px 0px rgba(64, 158, 255, 0.3);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFang SC;
        font-size: 20px;
        color: #ffffff;
        margin-right: 10px;
      }
      &-button2 {
        cursor: pointer;
        width: 120px;
        height: 40px;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(64, 158, 255, 0.3);
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: PingFang SC;
        font-size: 20px;
        color: #409eff;
        margin-right: 10px;
      }
    }
  }
}
</style>
